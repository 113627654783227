<template>
    <ODialog v-model:show="showDialog" disableResize size="lg" :title='$t("Give Feedback to Omega 365")' :disableMovable="false" disableFade :target="targetEl">
        <div class="o365-dialog-body d-flex flex-column">
            <OUploadDrop class="d-flex flex-column flex-grow-1 w-100" hideProgressBar :dataObject="dsAttachments" multiple="multiple" :beforeUpload="() => beforeUpload()">
                <div class="m-2">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" :value="dsDatabaseSetup.current.BugProcess_ID" name="af-navbar-process-radio" v-model="selectedProcess" id="af-navbar-bug" checked>
                        <label class="form-check-label" for="af-navbar-bug">
                            <span><b>{{ $t("Bug Report") }}</b> {{ $t("Something went wrong") }}</span>
                        </label>
                    </div>
                    <div class="form-check" v-if="dsDatabaseSetup.current.MissingFeatureProcess_ID">
                        <input class="form-check-input" type="radio" :value="dsDatabaseSetup.current.MissingFeatureProcess_ID" name="af-navbar-process-radio" v-model="selectedProcess" id="af-navbar-missing-feature">
                        <label class="form-check-label" for="af-navbar-missing-feature">
                            <span><b>{{ $t("Missing Feature") }}</b> {{ $t("Feature is required to get the job done") }}</span>
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" :value="dsDatabaseSetup.current.FeatureProcess_ID" name="af-navbar-process-radio" v-model="selectedProcess" id="af-navbar-feature">
                        <label class="form-check-label" for="af-navbar-feature">
                            <span><b>{{ $t("Feature Request") }}</b> {{ $t("Help us improve Omega 365") }}</span>
                        </label>
                    </div>
                </div>
                <div>
                    <label>{{ $t("Product") }}</label>
                    <ODataLookup 
                        disableRecent
                        class="form-control form-control-sm"
                        :bind="sel => { selectedProduct.ID = sel.OrgUnit_ID; selectedProduct.Name = sel.Name; }"
                        :data-object="dsProducts"  
                        :value="selectedProduct.Name"
                    > 
                        <OColumn field="Name" width="350"></OColumn>
                    </ODataLookup> 
                </div>
                <div class="mt-1">
                    <label>{{ $t("Title") }}</label>
                    <input class="form-control form-control-sm" v-model="dsWorkflow.current.Title"/>
                </div>
                <div class="mt-1">
                    <label>{{ $t("Description") }}</label>
                    <OTextArea 
                        v-model="dsWorkflow.current.Description"
                        class="form-control form-control-sm"
                        autoGrow
                        rows="4"
                        noResize/>
                </div>
                <div class="mt-3">
                        <div class="lh-lg pb-2 d-flex align-items-center gap-2 justify-content-between">
                            <div>
                                {{ $t("Drag and drop images here, or ") }}
                                <OFileUploadButton :dataObject="dsAttachments" hideProgressBar :text="$t('Choose Files...')" :beforeUpload="() => beforeUpload()" btnClass="btn btn-outline-primary btn-sm align-bottom"/>
                            </div>
                        </div>        
                        <div>
                            <OAttachments :dataObject="dsAttachments" showFileNames></OAttachments>
                        </div>
                </div>
                <div class="mt-auto">
                    <div class="my-3 border"></div>
                    <div class="d-flex" >
                        <div class="ms-auto">
                            <button class="btn btn-sm btn-primary ms-1" @click="submitWorkflow">
                                <span class="me-1">{{ $t("Submit") }}</span>
                                <div class="spinner-border spinner-border-sm" role="status" v-if="submitting">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </OUploadDrop>
        </div>
    </ODialog>
</template>
  
<script setup>
import { ref, onMounted, defineExpose, inject } from 'vue';
import { ODialog, OTextArea, Overlay } from 'o365-ui-components';
import { getOrCreateDataObject } from 'o365-dataobject';
import { ODataLookup } from 'o365-datalookup';
import { OColumn } from 'o365-datagrid';
import { getOrCreateProcedure, context } from 'o365-modules';
import { OAttachments, OFileUploadButton, OUploadDrop } from 'o365-fileupload';
 
const targetEl = ref();
const showDialog = ref(false);
const selectedProduct = ref({
    ID: null,
    Name: null
});
const selectedProcess = ref(null);

const setOnOpen = inject('set-on-open', () => {});
setOnOpen(() => {
    showDialog.value = true;
});

function beforeUpload(){
    { return { 'Item_ID': dsWorkflow.current.ID } }
}

const proceCreateEmptyProcess = getOrCreateProcedure({ id: "proceCreateEmptyProcess", procedureName: "astp_Scope_EmptyProcess"});
const procInitiateWorkflow = getOrCreateProcedure({ id: "procInitiate", procedureName: "astp_Scope_ItemInitiate" });
    
const dsWorkflow = getOrCreateDataObject({
    "id": `dsWorkflow-Navbar`,
    "isStaticId": true,
    "viewName": "aviw_Scope_ItemsForRegistration",
    "distinctRows": false,
    "uniqueTable": "aviw_Scope_ItemsForRegistrationUniqueTable",
    "allowUpdate": true,
    "allowInsert": false,
    "allowDelete": false,
    "appendData": false,
    "selectFirstRowOnLoad": true,
    "fields": [
        {
            "name": "ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Title",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Description",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Process_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "OrgUnit",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "OrgUnit_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
    ],
    "masterDataObject_ID": null,
    "masterDetailDefinition": [],
    "clientSideHandler": false,
    "maxRecords": 1,
    "dynamicLoading": false,
    "whereClause": null,
    "filterString": null,
    "disableAutoLoad": false,
    "optimisticLocking": false
});

const dsProducts = getOrCreateDataObject({
    "id": `dsProducts-Navbar`,
    "isStaticId": true,
    "viewName": "sviw_O365_Products",
    "distinctRows": false,
    "uniqueTable": null,
    "allowUpdate": false,
    "allowInsert": false,
    "allowDelete": false,
    "appendData": false,
    "selectFirstRowOnLoad": true,
    "fields": [
        {
            "name": "ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Name",
            "sortOrder": 1,
            "sortDirection": "asc",
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "OrgUnit_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
    ],
    "masterDataObject_ID": null,
    "masterDetailDefinition": [],
    "clientSideHandler": false,
    "maxRecords": 100,
    "dynamicLoading": false,
    "whereClause": null,
    "loadRecents": false,
    "filterString": null,
    "disableAutoLoad": false,
    "optimisticLocking": false
});

const dsDatabaseSetup = getOrCreateDataObject({
    "id": `dsDatabaseSetup-Navbar`,
    "isStaticId": true,
    "viewName": "stbv_Database_Setup",
    "distinctRows": false,
    "uniqueTable": null,
    "allowUpdate": false,
    "allowInsert": false,
    "allowDelete": false,
    "appendData": false,
    "selectFirstRowOnLoad": true,
    "fields": [
        {
            "name": "BugProcess_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "FeatureProcess_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "MissingFeatureProcess_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
    ],
    "masterDataObject_ID": null,
    "masterDetailDefinition": [],
    "clientSideHandler": false,
    "maxRecords": 1,
    "dynamicLoading": false,
    "whereClause": null,
    "filterString": null,
    "disableAutoLoad": false,
    "optimisticLocking": false
})

const dsAttachments = getOrCreateDataObject({
    "id": "dsAttachments",
    "viewName": "aviw_Scope_ItemsFiles",
    "distinctRows": false,
    "uniqueTable": "atbv_Scope_ItemsFiles",
    "allowUpdate": true,
    "allowInsert": true,
    "allowDelete": true,
    "appendData": false,
    "selectFirstRowOnLoad": true,
    "fields": [
        {
            "name": "FileName",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "PrimKey",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "FileSize",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "FileRef",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Extension",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Created",
            "sortOrder": 1,
            "sortDirection": "asc",
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Updated",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "CreatedBy",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "FileUpdated",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Item_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        }
    ],
    "masterDataObject_ID": null,
    "masterDetailDefinition": [],
    "clientSideHandler": false,
    "maxRecords": -1,
    "dynamicLoading": false,
    "whereClause": null,
    "filterString": null,
    "disableAutoLoad": false,
    "optimisticLocking": false
})

const submitting = ref(false);
async function submitWorkflow(){
    submitting.value = true;
    dsWorkflow.current.Process_ID = selectedProcess.value ?? dsDatabaseSetup.current.BugProcess_ID;
    dsWorkflow.current.OrgUnit_ID = selectedProduct.value.ID;
    await dsWorkflow.save();
    await procInitiateWorkflow.execute({
        ID: dsWorkflow.current.ID,
        OrgUnit_ID: selectedProduct.value.ID
    });
    submitting.value = false;
    location.href = `/scope-workflow?ID=${dsWorkflow.current.ID}`;
} 

function show(){
    showDialog.value = true;
} 

onMounted(() => {
    setTimeout(async function(){
        showDialog.value = true;
        var result = await proceCreateEmptyProcess.execute({
            OrgUnit_ID: context.id
        });
        var id = result.Table[0].ID;
        dsWorkflow.recordSource.whereClause = `ID = ${id}`;
        dsAttachments.recordSource.whereClause = `Item_ID = ${id}`;
        dsWorkflow.load();
        dsAttachments.load();
        dsDatabaseSetup.load();
    }, 100);
})

defineExpose({ show });

</script>